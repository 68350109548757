.textAlignCenter {
    text-align: center
}

.displayFlex {
    display: flex;
}

.alignItemsCenter {
    align-items: center;
}

.paddingLeft {
    padding-left: 1rem;
}

.paddingRight {
    padding-right: 1rem;
}

.marginTop {
    margin-Top: 1rem;
}

.marginLeft {
    margin-left: 1rem;
}

.marginRight {
    margin-right: 1rem;
}

.badgeComponent{
    border-radius: 1.5rem;
    font-size: calc(.45em + .45vw);
    background-color: #edf1f7;
    padding: .25rem .625rem;
    margin: .25rem;
}

.blueBadgeComponent {
    background-color: #2c8cff;
    color: white;
    border-radius: 10px;
    padding: 10px;
    margin-right: .5rem;
    margin-top: .25rem;
}

.cardComponent {
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin: 1rem;
    border-radius: 5px;
    box-shadow: 11px 10px 22px -10px rgba(0,0,0,0.32);
    background-color:white;
}

/* Project Card Styles */

.projectCardTitle {
    margin: 1rem;
}

.projectCardHeader {
    max-width: 50%;
}

.projectCardDescription {
    font-size: calc(.5em + .5vw);
    margin: 1rem;
}

.projectCardBadgeList {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
}

.projectCardImageContainer {
    height: auto;
    width: 100%;
    border-radius: 0px 0px 5px 5px;
    text-align: center;
}

.projectCardImage {
    height: auto;
    text-align: center;
    max-width: 100%;
}

/* About Card Styles */

.aboutCardDivider {
    height: 1px;
    margin: 15px;
    background-color: #f1f1f1;
}

.aboutCardSummaryContainer {
    width: 50%;
}

.aboutCardContainer {
    display: flex;
}

.aboutCardSummaryImageContainer {
    text-align: center;
}

.aboutCardSummaryImage {
    border-radius: 100%;
    width: 50%;
}

.aboutCardBadgeContainer { 
    display: flex;
    flex-wrap: wrap;
}

.aboutCardSummaryDetail {
    color: #b1b1b1;
}

/* about card grid */

.MSFTSummaryGrid {
    grid-area: MSFT;
}

.MSFTMainGrid {
    grid-area: MSFTMain;
}

.DDSSummaryGrid {
    grid-area: DDS;
}

.DDSMainGrid {
    grid-area: DDSMain;
}

.EducationSummaryGrid{
    grid-area: Education;
}

.EducationMainGrid{
    grid-area: EducationMain;
}


.avatarSummaryGrid {
    grid-area: avatar;
}

.avatarMainGrid {
    grid-area: avatarMain;
}

.dividerGrid1 {
    grid-area: divider1;
}

.dividerGrid2 {
    grid-area: divider2;
}

.dividerGrid3 {
    grid-area: divider3;
}

.skillsSummaryGrid {
    grid-area: skills;
}

.skillsMainGrid {
    grid-area: skillsMain;
}

.bonusSummaryGrid {
    grid-area: bonus;
}

.bonusMainGrid {
    grid-area: bonusMain;
}

.badgeContainer {
    margin: 1rem;
    border-radius: 5px;
    max-width: 20vw;
    box-shadow: 11px 10px 22px -10px rgba(0,0,0,0.32);
    background-color:white;
    padding: 1rem;
}

.badgeContainerIcon {
    margin-right: 1rem;
}

.badgeContainerTitle {
    margin-top: 1rem;
    margin-bottom: .5rem;
}

.badgeContainerDivider {
    height: 1px;
    background-color: #edf1f7;
    margin-bottom: .5rem;
}

.container {
    display: grid;
    grid-template-rows: auto;
    grid-template-areas: 
        "MSFT MSFTMain MSFTMain"
        "divider1 divider1 divider1"
        "DDS DDSMain DDSMain"
        "divider2 divider2 divider2"
        "avatar avatarMain avatarMain"
        "divider3 divider3 divider3"
        "bonus bonusMain bonusMain"
        
}

.introContainer {
    display: grid;
    grid-template-rows: auto;
    grid-template-areas: 
        "avatar avatarMain avatarMain"
        "divider1 divider1 divider1"
        "education educationMain educationMain"
        "divider2 divider2 divider2"
        "skills skillsMain skillsMain"
        "divider3 divider3 divider3"
        "bonus bonusMain bonusMain"
}

@media only screen and (max-width:620px) {
    /* For mobile phones: */
    .container {
        display: flex;
        flex-wrap: wrap;
        grid-template-rows: auto;
        grid-template-areas: 
            "DDS DDSMain DDSMain"
            "divider1 divider1 divider1"
            "avatar avatarMain avatarMain"
            "divider2 divider2 divider2"
            "bonus bonusMain bonusMain"
    }

    .introContainer {
        display: grid;
        display: flex;
        flex-wrap: wrap;
        grid-template-rows: auto;
        grid-template-areas: 
            "avatar avatarMain avatarMain avatarMain"
            "divider1 divider1 divider1"
            "education educationMain educationMain"
            "divider2 divider2 divider2"
            "skills skillsMain skillsMain"
            "divider3 divider3 divider3"
            "bonus bonusMain bonusMain"
    }

    .aboutCardDivider {
        height: 1px;
        margin: 15px;
        background-color: #f1f1f1;
        width: 100%;
    }

    .badgeContainer {
        margin: 1rem;
        border-radius: 5px;
        max-width: 60vw;
        box-shadow: 11px 10px 22px -10px rgba(0,0,0,0.32);
        background-color:white;
        padding: 1rem;
    }

    .badgeContainerIcon {
        margin-right: 1rem;
        font-size: 6vw;
    }

    .projectCardHeader {
        max-width: 100%;
    }

    .projectCardDescription {
        font-size: calc(.75em + .75vw);
        margin: 1rem;
    }

    .badgeComponent{
        border-radius: 1.5rem;
        font-size: calc(.65em + .65vw);
        background-color: #edf1f7;
        padding: .25rem .625rem;
        margin: .25rem;
    }
}
