.flexSpaceBetween {
    display: flex;
    justify-content: space-between
}

.avatarContainer {
    margin: 5px;
    position: relative;
}

.linkContainer {
    display: flex;
    justify-content: center;
    color: white;
    position: absolute;
    margin-left: calc(100% - (3 * 2.5vw) - (.75em));
    margin-top: calc(.5em + .5vw);
    margin-bottom: calc(.5em + .5vw);
}

.FAIconContainer {
    margin-right: .25em
}

.avatar {
    border-radius: 50%;
    position: absolute;
    height: 100%;
    margin-left: 1em;
}

@media only screen and (max-width:620px) {
    /* For mobile phones: */
    .avatar{
        display: none;
    }
    .avatarContainer{
        display: none;
    }
    .linkContainer {
        display:none;
        justify-content: center;
        color: gray;
        position: absolute;
    }
    
    .FAIconContainer {
        margin-right: .0em
    }
  }

.inlineContainer{
    display: inline-block;
    margin: 0px auto;
}

.flexContainer {
    display:flex;
}

.contentMenu {
    text-align: center;
    background-color: #2c8cff;
    z-index: 2;
}

.contentMenuLocked {
    top: 0;
    position: fixed;
    width: 100%;
}

.contentMenuItem {
    font-size: calc(.65em + .65vw);
    transition: font-size .4s;
    /*margin-right: calc((100% - 255px) / 4);*/
    /*padding: calc(.45em + .45vw);*/
    padding: calc(.3em + .3vw);
    position: relative;
    color: #f5f5f5;
} 


.subMenuContent {
    font-size: calc(.65em + .65vw);
    display:none;
    opacity: 0;
    transition: opacity .4s;
    transition-timing-function: ease;
    position: relative;
}
/*
.subMenuContent:nth-child(1) {transition-delay: .3s}
.subMenuContent:nth-child(2) {transition-delay: .2s}
.subMenuContent:nth-child(3) {transition-delay: .1s}
*/
.contentMenuItem:hover .subMenuContent{
    opacity: 1;
    transition: opacity .5s;
    transition-timing-function: ease;
}



.contentMenuItem:hover .subMenuContent:nth-child(1) {
    display: flex;
    opacity: 1;
    transition: opacity .5s;
    transition-timing-function: ease;
    transition-delay: .1s;
}

.subMenuContent:nth-child(1):hover .subMenuLine {
    opacity: 1;
    height: calc(.65em + .65vw) !important;
}

.contentMenuItem:hover .subMenuContent:nth-child(2) {
    display: flex;
    opacity: 1;
    transition: opacity .5s;
    transition-timing-function: ease;
    transition-delay: .2s
}

.subMenuContent:nth-child(2):hover .subMenuLine {
    opacity: 1;
    height: calc(.65em + .65vw) !important;
}

.contentMenuItem:hover .subMenuContent:nth-child(3) {
    display: flex;
    opacity: 1;
    transition: opacity .5s;
    transition-timing-function: ease;
    transition-delay: .3s
}

.subMenuContent:nth-child(3):hover .subMenuLine {
    opacity: 1;
    height: calc(.65em + .65vw);
}

/*.contentMenuItem:last-child {
    margin-right:0;
}*/

.contentMenuIcon {
    margin-right: 5px;
}

.rectangleContainer {
    display: flex;
    justify-content: center;
}

.expandingRectangle {
    display: inline-block;
    background-color: #f5f5f5;
    margin-top: 2px;
    height: 2px;
    width: 0%;
    transition: width .4s, font-size .4s;
    transition-timing-function: ease
}

.contentMenuItem:hover .expandingRectangle { 
    width: 100% !important;
}


.subMenuContainer {
    text-align: left;
    position: absolute;
    /*opacity: 0.0;
    transition: opacity .2s;
    transition-timing-function: ease;*/
    width: 200%;
}

.subMenuDot {
    margin-top: 5px;
    height: 15px;
    width: 15px;
    background-color: black;
    border-radius: 50%;
    display: inline-block;
}

.subMenuLine {
    width: 2.5px;
    margin-right: 6px;
    height: 0;
    transition: height .3s;
    transition-timing-function: ease;
    background-color: black;
}



.menuFlexBox {
    display: flex;
    margin-top: calc(.5em + .5vw);
    margin-bottom: calc(.5em + .5vw);
}

@keyframes menuAnimation {
    0%   { 
        width: 0px; 
        background-color:darkgray;
        animation: menuAnimation 5s;
        height: 10px;
        width: 50px;
    }
    100% { width: 50px; }
}


