.lineDivider {
    width: 100%;
    /*background-color: darkgray;*/
    height: 2px;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.verticalLine {
    background-color: #2c8cff;
    height: 100%;
    position: absolute;
    width: 4px;
    
}