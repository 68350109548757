.section {
    background-color: #999999;
}

.flexSpaceEvenly {
    display: flex;
    justify-content: center;
}

.jumbotronContainer {
    height: 75vh;
    text-align: center;
    background-image: URL('backgrnd.png');
    color: #f5f5f5;
}

.titleContainer {
    text-align: center;
    background-color: #2c8cff;
    color: #f5f5f5;
}

.title {
    font-size: calc(2.5em + 2.5vw);
}

.subtitleContainer {
    display: flex;
}

.subtitleContent {
    font-size: calc(1em + 1vw);
    color: #6b6b6b;
    /*margin-right: .4em;
    margin-left: .4em;*/
    text-align: center;
    width: 16vw;
    margin-right: 1vw;
}

.iconContainer {
    display: flex;
    color: grey;
}

.icon {
    font-size: 3vw;
    margin: 5px;
    display: none;
}

.aboutContainer {
    display: flex;
    justify-content: space-evenly;
    background-color: #2c8cff;
}

.about {
    font-size: calc(.75em + .55vw);
    color: #f5f5f5;
}

.bannerContainer {
    display: none;
    margin-top: 15vw;
    justify-content: center;
}

.bannerTitle {
    font-size: calc(.25em + .25vw);
}

.bannerImage {
    width: 100%;
    height: auto;
}

.activeContent{
    /*padding:40px calc((100vw - 960px)/2)*/
    margin-left: 20%;
    width: 60%;
}

.footerContainer {
    display: flex;
    justify-content: center;
    background-color: #f5f5f5;
    height: 3.5em;
    margin-top: 7.5em;
    box-shadow: 0px 2px 3px 4px lightgrey;
    padding-top: 3.5em;
    padding: 1.5em;
}

.footerSection {
    margin-right: 2.5em;
}

.footerFlexSection {
    margin-right: .5em;
}

@media only screen and (max-width:620px) {
    /* For mobile phones: */
    .activeContent {
        margin-left: 0%;
        width:100%;
    }

    .jumbotronContainer {
        height: 75vh;
        text-align: center;
        zoom: 75%;
        color: #f5f5f5;
        background-position-x: -553px;
        background-position-y: -10px;
    }

    .footerContainer {
        display: none;
    }
    
  }