.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

a {
  color: inherit;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.flex {
  display: flex;
}

.width100 {
  width: 100%;
}

.title {
  font-size: 50px;
}

.subTitle {
 font-size: 2vw;
}

.hugeText {
 font-size: 50px;
}

.bigText {
  font-size: calc(.7em + .7vw);
}

.mediumText {
  font-size: calc(.6em + .6vw);
}

.liText {
  font-size: calc(.5em + .5vw);
}

.smallText {
  font-size: 1vw;
}

.iconSize {
  font-size: 2.5vw;
}

.iconBadgeSize {
  font-size: 3vw;
}

.blueBackground {
  background-color: #2c8cff; 
}

.blueText {
  color: #2c8cff; 
}

.grayText {
  color: gray;
}

.center {
  text-align: center;
}

ul {
  list-style: none;
}

ul li::before {
  content: "\2022";
  color: #2c8cff;
  font-weight: bold;
  display: inline-block; 
  width: 1em;
  margin-left: -1em;
}

.thumbnail {
  margin: 0px;
  position: relative;
  width: 100%;
  height: 40vh;
  overflow: hidden;
}

.thumbnail img {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: auto;
  -webkit-transform: translate(-50%,-50%);
      -ms-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
}
.thumbnail img.portrait {
  width: 100%;
  height: auto;
}

li {
  margin-top: .25em;
}


@media only screen and (max-width:620px) {
  /* For mobile phones: */
  .activeContent {
      margin-left: 0%;
      width:100%;
  }
  .subTitle {
    font-size: 7vw;
   }

   .hugeText {
     font-size: 8vw;
   }
   
   .bigText {
     font-size: calc(1.25em + 1.25vw);
   }

   .liText {
     font-size: calc(.75em + .75vw);
   }
   
   .mediumText {
    font-size: calc(1em + 1vw);
   }
   
   .smallText {
     font-size: 2vw;
   }

   .iconSize {
     font-size: 7vw;
   }

   .thumbnail {
    margin: 0px;
    position: relative;
    width: 100%;
    height: 20vh;
    overflow: hidden;
  }
  
  .thumbnail img {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 100%;
    width: auto;
    -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
  }
  .thumbnail img.portrait {
    width: 100%;
    height: auto;
  }
}

.App-header {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #262626;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
