.displayFlex {
    display: flex;
}

.aboutContainer {
    position: relative;
}

.contentNSection:nth-child(odd) {
    background-color:#f5f5f5;
    z-index: 1;
}

.contentNSection:nth-child(even) {
    background-color:#f5f5f5;
    z-index: 1;
}

.contentNHeader{
    color:white;
    background-color: #2c8cff;
    padding: 1rem;
    font-size: 45px;
}

.bioContainer {
    position: relative;
}

.aboutTitle {
    margin-top: 5%;
}

.skillContainer{
    display: flex;
    position: relative;
    
}

.skillContent{
    margin-left: 2vw; 
}

.skillCategoryContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.skillBulletContainer {
    margin-left: 1vw;
}

.skillLine {
    margin: .5vw;
}

.skillColumn {
    padding: 10px;
}

.picture {
    width: 50%;
}

.hidden {
    display: none;
}

.chevronContainer {
    text-align: center;
}

.Chevron {
    color: #2c8cff;
}